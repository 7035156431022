import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Link } from 'gatsby';

const TagList = ({ tags }) => {
    if (tags.length === 0) {
        return false;
    }
    return (
        <div css={css(tagContainerStyle)}>
            <p css={css(wordStyle)}>Tag : </p>
            {tags.map((tag, index) => (
                <div key={tag} css={css(tagStyle)}>
                    <Link to={`/tags/${tag}`}>{tag}</Link>
                    {index + 1 === tags.length ? '' : ','}
                </div>
            ))}
        </div>
    );
};

TagList.propTypes = {
    tags: PropTypes.array,
};

export default TagList;

const tagContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
};

const wordStyle = {
    fontWeight: 400,
};

const tagStyle = {
    margin: '0 5px',
    '& a': {
        boxShadow: 'none',
        color: 'inherit',
        fontWeight: 700,
    },
};
