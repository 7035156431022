import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import Carsouel from './Carsouel';

import { rhythm } from '../../utils/typography';

function ImgTextSlider({ images, style }) {
    const [index, setIndex] = useState(0);

    return (
        <div css={css(style)}>
            <Carsouel images={images} handleIndex={setIndex} />
            <h3 css={css(captionStyle)}>{images[index] && images[index].text}</h3>
        </div>
    );
}

ImgTextSlider.propTypes = {
    images: PropTypes.array,
    style: PropTypes.object,
};

export default ImgTextSlider;

const captionStyle = {
    marginTop: rhythm(1 / 2),
    fontWeight: 400,
    fontSize: '1.2rem',
    minHeight: '21px',
};
