import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { css } from '@emotion/core';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ImgTextSlider from '../components/Slider/ImgTextSlider';
import CommentDisqus from '../components/CommentDisqus';
import TagList from '../components/TagList';

import { rhythm, scale } from '../utils/typography';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BlogPostTemplate = ({ data, pageContext, location }) => {
    const post = data.markdownRemark;
    const siteTitle = data.site.siteMetadata.title;
    const { previous, next } = pageContext;

    const { social, author, siteUrl } = data.site.siteMetadata;

    const { title, description, images = [], cover, tags = [] } = post.frontmatter;

    const image = cover ? cover.childImageSharp.fluid.src : '';
    return (
        <Layout location={location} title={siteTitle} social={social} author={author}>
            <SEO title={title} description={description || post.excerpt} image={image} />
            <div
                css={css({
                    marginLeft: `auto`,
                    marginRight: `auto`,
                    maxWidth: rhythm(24),
                })}
            >
                <article>
                    <header>
                        <h1
                            css={css({
                                marginTop: rhythm(1),
                                marginBottom: rhythm(1),
                            })}
                        >
                            {title}
                        </h1>
                        <p css={css({ marginBottom: rhythm(1 / 2) })}>{description}</p>
                        <p
                            css={css({
                                ...scale(-1 / 5),
                                display: `block`,
                                marginBottom: rhythm(1 / 2),
                                textAlign: 'right',
                            })}
                        >
                            {post.frontmatter.date}
                        </p>
                    </header>
                    {images.length > 0 && (
                        <ImgTextSlider
                            images={images}
                            style={{
                                marginTop: rhythm(1),
                                marginBottom: 0,
                            }}
                        />
                    )}
                    <section css={css(postStyle)} dangerouslySetInnerHTML={{ __html: post.html }} />
                    <TagList tags={tags} />
                    <hr
                        css={css({
                            marginBottom: rhythm(1),
                        })}
                    />
                    <footer>
                        <Bio />
                    </footer>
                </article>

                <nav>
                    <ul
                        css={css({
                            display: `flex`,
                            flexWrap: `wrap`,
                            justifyContent: `space-between`,
                            listStyle: `none`,
                            padding: 0,
                            marginLeft: 0,
                        })}
                    >
                        <li>
                            {previous && (
                                <Link css={css(linkStyle)} to={previous.fields.slug} rel="prev">
                                    ← {previous.frontmatter.title}
                                </Link>
                            )}
                        </li>
                        <li>
                            {next && (
                                <Link css={css(linkStyle)} to={next.fields.slug} rel="next">
                                    {next.frontmatter.title} →
                                </Link>
                            )}
                        </li>
                    </ul>
                </nav>
                <div>
                    <CommentDisqus siteUrl={siteUrl} title={title} url={post.fields.slug} />
                </div>
            </div>
        </Layout>
    );
};

BlogPostTemplate.propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
    location: PropTypes.object,
};

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                author {
                    name
                    summary
                }
                social {
                    facebook
                    twitter
                    instagram
                }
                siteUrl
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            fields {
                slug
            }
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
                tags
                images {
                    text
                    img {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                cover {
                    childImageSharp {
                        fluid {
                            src
                        }
                    }
                }
            }
        }
    }
`;

const linkStyle = {
    boxShadow: 'none',
    color: 'inherit',
    fontWeight: 700,
};

const postStyle = {
    '& .language-text': {
        whiteSpace: 'break-spaces',
    },
};
