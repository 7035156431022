import React from 'react';
import PropTypes from 'prop-types';

import { Disqus } from 'gatsby-plugin-disqus';

function CommentDisqus({ siteUrl, url, title }) {
    let disqusConfig = {
        url: `${siteUrl}${url}`,
        identifier: `linda_${url}`,
        title,
    };
    return <Disqus config={disqusConfig} />;
}

CommentDisqus.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    siteUrl: PropTypes.string,
};

export default CommentDisqus;
