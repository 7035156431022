import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { css } from '@emotion/core';

function Carsouel({ images, handleIndex }) {
    const settings = {
        dots: true,
        centerPadding: '100px',
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        afterChange: (current) => handleIndex(current),
        customPaging: () => <div css={css(dotStyle)}></div>,
    };
    return (
        <Slider {...settings} css={css(slickStyle)}>
            {images.map(({ img, text }) => {
                const {
                    childImageSharp: {
                        fluid: { src },
                    },
                } = img;
                return (
                    <div key={img} css={css(itemStyle)}>
                        <img src={src} alt={text} css={css(imgStyle)} />
                    </div>
                );
            })}
        </Slider>
    );
}

Carsouel.propTypes = {
    images: PropTypes.array,
    handleIndex: PropTypes.func,
};

export default React.memo(Carsouel);

const itemStyle = {
    padding: '0 10px',
    outline: 'none',
};

const imgStyle = {
    margin: '0',
};

const slickStyle = {
    '& .slick-dots': {
        bottom: '-10px',
        '& .slick-active > div': {
            width: '40px',
            backgroundColor: 'rgb(0, 0, 0)',
            opacity: '0.75',
        },
        '& li': {
            width: 'initial',
            height: 'initial',
            margin: '0',
        },
    },
    '& .slick-prev:before, & .slick-next:before': {
        color: '#f8f8f8',
        padding: '10px 5px',
        fontSize: '28px',
    },
    '& .slick-arrow': {
        zIndex: 1,
        width: '38px',
        height: '48px',
    },
    '& .slick-next': {
        right: '-8px',
        transform: 'translate(-50%, -50%)',
    },
    '& .slick-prev': {
        left: '-8px',
        transform: 'translate(50%, -50%)',
    },
    '& .slick-disabled': {
        display: 'none !important',
    },
};

const dotStyle = {
    height: '4px',
    width: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 8px',
    backgroundColor: 'rgb(155, 155, 155)',
    margin: '2px',
    borderRadius: '50px',
    transition: 'all 0.3s linear 0s',
};
